import { navigate } from "gatsby"
import axios from "axios"

import { URLS } from "../../config"

import {
  LOADING,
  LOADING_CLEAR,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
} from "../types"
/** ------------------------------------------------------------------
 *? Login
 */
export const login = ({ email, password }) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await axios.post(
        `${URLS.TESTING}/online-members-login`,
        {
          email,
          password,
        }
      )
      setToken(data.token)
      dispatch({ type: AUTH_USER })
      navigate("/profile")
    } catch (error) {
      console.log(error)
      dispatch({
        type: AUTH_ERROR,
        payload: "Error on login",
      })
    }
  }
}
/** ------------------------------------------------------------------
 *? Logout
 */
export const Logout = () => {
  return async dispatch => {
    clearToken()
    dispatch({ type: UNAUTH_USER })
    navigate("/login")
  }
}
/** ------------------------------------------------------------------
 *? Loading Clear
 */
export const LoadingClear = () => {
  return async dispatch => {
    dispatch({ type: LOADING_CLEAR })
  }
}
/** ------------------------------------------------------------------
 * [Local_Storage_Helpers]
 */
// const getToken = () => {
//   return localStorage.getItem("ID_TOKEN_KEY")
// }
const clearToken = () => {
  localStorage.removeItem("ID_TOKEN_KEY")
}
const setToken = token => {
  localStorage.setItem("ID_TOKEN_KEY", token)
}
