import React from "react"

const ExternalLink = props => (
  <a
    key={props.key}
    className={props.className}
    href={props.href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {props.children}
  </a>
)

export default ExternalLink
