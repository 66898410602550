import { DarkTheme } from "../../styles"

const Bubbles = {
  particles: {
    number: {
      value: 160,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: "top",
      out_mode: "out",
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: "bubble",
      },
      onclick: {
        enable: true,
        mode: "repulse",
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0,
      },
      repulse: {
        distance: 400,
        duration: 4,
      },
    },
  },
}
const NightSky = {
  particles: {
    number: {
      value: 60,
      density: {
        enable: true,
        value_area: 1500,
      },
    },
    line_linked: {
      enable: true,
      opacity: 0.02,
    },
    move: {
      direction: "right",
      speed: 0.05,
    },
    size: {
      value: 1,
    },
    opacity: {
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.05,
      },
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      push: {
        particles_nb: 1,
      },
    },
  },
  retina_detect: true,
}
const Hex = {
  particles: {
    number: {
      value: 20,
      density: {
        enable: true,
        value_area: 500,
      },
    },
    color: {
      value: DarkTheme.primary,
    },
    shape: {
      type: "polygon",
      stroke: {
        width: 2,
        color: DarkTheme.primary,
      },
      polygon: {
        nb_sides: 6,
      },
    },
    opacity: {
      value: 0.5,
      random: true,
    },
    size: {
      value: 10,
      random: true,
    },
    line_linked: {
      enable: false,
      distance: 100,
      color: DarkTheme.primary,
      opacity: 0.2,
      width: 2,
    },
    move: {
      enable: true,
      speed: 4,
      direction: "bottom",
      random: true,
      straight: true,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: ["grab", "bubble"],
      },
      onclick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 0.7,
        },
      },
      bubble: {
        distance: 600,
        size: 12,
        duration: 1,
        opacity: 0.8,
        speed: 2,
      },
      repulse: {
        distance: 400,
        duration: 0.4,
      },
      push: {
        particles_nb: 20,
      },
      remove: {
        particles_nb: 10,
      },
    },
  },
  retina_detect: true,
}

export { Bubbles, NightSky, Hex }
