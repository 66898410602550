import React from "react"
import Styled from "styled-components"

const Burger = Styled.button`
  position: absolute;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;
    
  &:focus {
    outline: none;
  }

  &:hover {
    div:nth-child(2) {
      width: ${({ open }) => (open ? "100%" : "70%")};
    }
    div:nth-child(3) {
      width: ${({ open }) => (open ? "100%" : "40%")};
    }
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open, show, theme }) =>
      show
        ? open
          ? theme.onBackground
          : theme.onBackground
        : open
        ? theme.onBackground
        : theme.background};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const MyBurger = ({ open, setOpen, show }) => {
  return (
    <Burger open={open} show={show} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </Burger>
  )
}

export default MyBurger
